<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
      :style="style_centerblock"
      class="text-center overflow-x-auto"
    >
      <div>
        <v-row
          align="center"
          justify="center"
        >
          <h1
            class="font-weight-light my-6"
            :style="style_welcome"
          >
            Welcome in PERSEUS NG
          </h1>
        </v-row>

        <v-row
          align="center"
          justify="center"
        >
          <h3 class="font-weight-light my-6">
            Your account has been created and is now in "<b>Pending</b>" status. <br>
          </h3>
        </v-row>

        <v-row
          align="center"
          justify="center"
        >
          <v-alert
            type="info"
            width="90%"
            elevation="10"
            :color="this.$store.state.darkTheme?'gmiddleblue':'gdarkblue'"
            prominent
            text
          >
            <h3 class="font-weight-light">
              Your account will have the status "pending" until its activation. <br>
              During this time, you can browse the PERSEUS NG² website. <br>
              However, you will not be able to access the clusters. <br>
              <i class="m-6">
                Activation of an account generally takes about 24 hours after joining a project.
              </i>
            </h3>
          </v-alert>
        </v-row>

        <h3 class="font-weight-light my-6">
          Your account is only validated if you are a member of at least one project.
        </h3>

        <v-row
          align="center"
          justify="center"
        >
          <v-alert
            type="error"
            color="gred"
            elevation="10"
            prominent
            width="100%"
            text
          >
            <h3>
              If you don't join an active project before a month,
              your account request will be canceled.
            </h3>
            <h3>
              <i>You will then have to make a new account creation request...</i>
            </h3>
          </v-alert>
        </v-row>

        <v-row class="my-6" />
        <v-divider
          class="my-6"
          :dark="this.$store.state.darkTheme ? true:false"
        />

        <v-card-actions class="my-4">
          <v-btn
            :dark="this.$store.state.darkTheme ? true:false"
            x-large
            color="gmiddleblue"
            to="/dashboard"
          >
            I will join a project later
            <v-icon>mdi-chevron-triple-right</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            :dark="this.$store.state.darkTheme ? true:false"
            x-large
            color="gmiddleblue"
            to="/my-projects/join-project"
          >
            I want to join a project now
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <br>
    </v-row>

    <v-btn
      text
      color="white"
      x-large
      exact
      absolute
      bottom
      left
      class="my-2 mx-2"
      height="70px"
      href="https://gricad.gricad-pages.univ-grenoble-alpes.fr/web/publicweb/index.html"
      target="_blank"
    >
      <!-- :style="this.$store.state.darkTheme?'filter: invert(1)':'filter: invert(0)'" -->
      <v-img
        v-if="this.$store.state.darkTheme"
        src="@/assets/logo_gricad_horizontal_dark.png"
        max-width="230px"
      />
      <v-img
        v-else
        src="@/assets/logo_gricad_horizontal.png"
        max-width="230px"
      />
    </v-btn>

    <br>
    <br>
    <br>
  </v-container>
</template>

<script>
  import config from '@/config'

  export default {
    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,
      bg_dark: config.colors.gricad_darkblue_dark2,
      glightblue: config.colors.g_lightblue,
      gmiddleblue: config.colors.g_middleblue,
      gdarkblue: config.colors.g_darkblue,
      gred: config.colors.g_red,
    }),

    computed: {
      style_centerblock () {
        return {
          //height: `${this.$store.state.windowH - 200}px`,
          'background-color': 'transparent',
          'font-size': '20px',
        }
      },
      style_welcome () {
        return {
          color: this.gmiddleblue,
          'font-size': '50px',
        }
      },
      style_bigbtn () {
        return {
          height: `${this.$store.state.windowH / 4}px`,
          width: `${this.$store.state.windowW / 4}px`,
          'background-color': this.blue,

        }
      },
    },

  }
</script>

<style lang="scss">

</style>
